<template>
    <div class="container">
        <div class="index-header">
            <div class="ih-title dp-flex">
                <img
                        style="cursor: pointer"
                        @click="goIndex"
                        width="330" height="80"
                        src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/lyg_new_logo.png"
                        alt="">
                <div class="ih-search">
                    <div class="ih-search-input">
                        <input v-model="keyword" class="ih-search-input-body" placeholder="请输入关键词进行搜索"/>
                        <div class="ih-search-input-button display-flex-center" @click="search">搜索</div>
                    </div>
                    <div class="ih-search-tag">
                        <div class="ih-search-tag-body" @click="choice_span(index)"
                             v-for="(item,index) in choice_search"
                             :key="index">
                            <div class="ih-search-tag-circle"
                                 :style="{'visibility':isChoiceActive === index ? 'visible':'hidden'}"/>
                            <div style="width: 84px;height: 35px"
                                 :class="['display-flex-center',isChoiceActive === index ? 'ih-search-tag-select' : '',]">
                                {{ item }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="ih-select dp-flex flex-center">
                <div class="ih-select-body">
                    <div :class="['isb-font','display-flex-center',isItemActive === index ? 'isb-font-select' : '',]"
                         v-for="(item,index) in list_items"
                         :key="index"
                         @click="choose_item(index,item.index)"
                    >
                        {{ item.name }}
                    </div>
                    <div class="isb-right">
                        <div class="isb-right-body" @click="goSellerCenter">
                            <Icon class="isb-right-body-icon" type="ios-add-circle-outline"/>
                            <span class="isb-right-body-text">机构入驻</span>
                        </div>
                        <div class="isb-right-body">
                            <Icon class="isb-right-body-icon" type="ios-create-outline"/>
                            <span class="isb-right-body-text" @click="goDemand">提需求</span>
                        </div>
                        <div class="isb-right-body" @click="goLogin">
                            <Icon class="isb-right-body-icon" type="ios-contact-outline"/>
                            <span class="isb-right-body-text">{{ token ? userName : '登录/注册' }}</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div style="height: 202px"/>
        <slot></slot>


        <footer-component></footer-component>
    </div>

</template>

<script>
    import logo from "@/assets/logo.png";
    import close from "@/assets/image/index/close.png";
    import {getNavConfig, getRouteConfig} from "../plugins/route";
    import {mapMutations, mapGetters} from "vuex";
    import {checkLogin} from "../plugins/api/loginApi";
    import {getIndexFloorDetail} from "@/plugins/api/indexApi";
    import {getLocalToken} from "../plugins/tools/storage";
    import { hostname,domain } from "@/config";

    export default {
        name: "IndexCom",
        watch: {
            $route: {
                immediate: true, // 一旦监听到路由的变化立即执行
                handler(to, from) {
                    let pageId = to.params.pageId;
                    getNavConfig().forEach((item, i) => {
                        if (item.path.split("/")[2] === pageId) {
                            this.isItemActive = i;
                        }
                    });
                }
            }
        },
        computed: {
            ...mapGetters({
                token: "userStore/token",
                userName: "userStore/userName",
                userId: "userStore/userId",
                enterpriseState: "userStore/enterpriseState"
            })
        },
        data() {
            return {
                keyword: "",
                other: {
                    ad: {
                        url: "",
                        pic: ""
                    }
                },
                erweiFlag: false,
                firstFlag: true,
                close: close,
                erwei: "",
                weModal: false,
                footHide: false,
                logo: logo,
                isAnli: 0,
                isLogin: false,
                dominCookie: "",
                isChoiceActive: 0,
                choice_search: [
                    "高校专利",
                    "科研成果"
                ],
                isItemActive: 0,
                list_items: getNavConfig()
            };
        },
        components: {
            "footerComponent": () => import("../components/index/homePageComponent/footerComponent")
        },
        mounted() {
            this.$forceUpdate();
            // this.getIndexShowDetail();
            this.$nextTick(() => {
                this.dominCookie = getLocalToken();
            })

        },

        methods: {
            goadminCenter() {
                window.open("http://admin.lygstrim.com/login");
            },
            goSellerCenter() {
                window.open("http://seller.lygstrim.com/broker-dist/user/login");
            },
            goRenzheng() {
                this.$router.push({
                    path: '/home/EnterpriseCertification'
                });
            },
            async getIndexShowDetail() {
                const json = await getIndexFloorDetail();
                if (json && json.code === 0) {
                    if (json.result.other) {
                        this.other = json.result.other;
                    }
                }
            },
            openZoosUrl() {
                openZoosUrl("chatwin");
            },
            goIndex() {
                this.$router.push({
                    path: "/"
                });
            },
            guanzhu() {
                this.weModal = true;
            },
            ...mapMutations({
                setUserToken: "userStore/setUserToken",
                setUserName: "userStore/setUserName",
                setUserAccountType: "userStore/setUserAccountType",
                setUserMbrMemberId: "userStore/setUserMbrMemberId",
                setUserId: "userStore/setUserId",
                setUserEnterpriseState: "userStore/setUserEnterpriseState"
            }),
            logOut() {
                this.setUserToken("");
                this.setUserName("");
                this.setUserAccountType(-1);
                this.setUserMbrMemberId("");
                this.setUserId("");
                this.setUserEnterpriseState("");
                this.$cookies.set("token", "", null, null, domain);
            },
            goLogin() {
                if (this.token || this.userId || this.dominCookie) {
                    this.goEnterprise();
                    return
                }
                this.$router.push({
                    path: `/login`,
                    params: {
                        type: 1
                    }
                });
            },
            goEnterprise() {
                checkLogin(this.$router);
                if (!this.token || !this.userId) {
                    return;
                }
                this.$router.push({
                    path: "/enterprise/enterprise-management-center",
                    query: {
                        id: 0
                    }
                });

            },
            goDemand() {
                checkLogin(this.$router);
                if (!this.token || !this.userId) {
                    return;
                }
                this.$router.push({
                    path: "/enterprise/enterprise-school-connection"
                });
            },
            goRegister() {
                this.$router.push({
                    name: `login`,
                    params: {
                        type: 4
                    }
                });
            },

            choose_item(index, routerIndex) {
                console.log('routerIndex', index)
                this.isItemActive = index;
                let nav = getRouteConfig().filter(x => x.navIndex === routerIndex);
                console.log('nav', nav)
                this.$router.push({
                    path: nav[0].path
                });
            },
            choice_span(index) {
                this.isChoiceActive = index;
            },
            search() {
                if (this.isChoiceActive === 0) {
                    this.$router.push({
                        path: "ResourceRetrieval",
                        query: {
                            "keyword": this.keyword
                        }
                    });
                } else {
                    this.$router.push({
                        path: "PatentSearch",
                        query: {
                            "keyword": this.keyword
                        }
                    });
                }

            }

        }
    };
</script>

<style lang="scss" scoped>
    /deep/ .ivu-affix {
        z-index: 999;
        top: 0;
    }

    .index-header {
        /*background-image: url("https://cnipy-public.oss-cn-shanghai.aliyuncs.com/cnipy/public/202203/lyg_bannera.png") !important;*/
        background-image: url("~@/assets/image/index/header-banner.jpg") !important;
        background-size: auto 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        z-index: 99;
    }

    .ih-title {
        width: 1200px;
        margin-top: 21px;
        justify-content: space-between;
    }

    .ih-search {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
    }

    .ih-search-input {
        display: flex;
        flex-direction: row;
    }

    .ih-search-input-body {
        width: 729px;
        height: 42px;
        background: #FFFFFF;
        border-radius: 6px 0 0 6px;
        outline: none;
        border: none;
        padding-left: 17px;
        font-size: 16px;
    }

    .ih-search-input-button {
        width: 96px;
        background-color: #2ACCDF;
        border-radius: 0 6px 6px 0;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        cursor: pointer;
    }

    .ih-search-tag {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        margin-top: 5px;
        margin-bottom: 25px;
    }

    .ih-search-tag-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
    }

    .ih-search-tag-circle {
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid rgba(54, 109, 224, 1);
    }

    .ih-search-tag-select {
        border-radius: 6px;
        background-color: rgba(54, 109, 224, 1);
    }

    .ih-select {
        width: 100%;
        height: 49px;
        border-top: 1px solid rgba(255, 255, 255, 0.3);
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        background: #1263e0;
    }

    .ih-select-body {
        width: 1200px;
        display: flex;
        flex-direction: row;
    }

    .isb-font {
        width: fit-content;
        height: 100%;
        font-size: 16px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.6);
        margin-left: 45px;
        cursor: pointer;
    }

    .isb-font:hover {
        color: #FFFFFF;
        border-bottom: 3px solid #FFFFFF;
    }

    .isb-font-select {
        color: #FFFFFF;
        border-bottom: 3px solid #FFFFFF;
    }

    .isb-right {
        flex: 1;
        display: flex;
        flex-direction: row;
        padding-left: 55px;
        justify-content: space-between;
        align-items: center;
        margin-left: 70px;
    }

    .isb-right-body {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
    }

    .isb-right-body-icon {
        font-size: 18px;
        margin-right: 5px;
        color: rgba(255, 255, 255, 0.6);
    }

    .isb-right-body:hover .isb-right-body-icon,
    .isb-right-body:hover .isb-right-body-text{
        color: rgba(255, 255, 255, 1);
    }

    .isb-right-body-text {
        font-size: 16px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.6);
    }

</style>
