import baseUrl from '@/plugins/http/baseUrl'
export function getRouteConfig() {
    return [
      {
        name: 'index',
        path: '/home/index',
        navIndex: 0
      },
      {
        name: 'patent-supermarket',
        path: '/home/patent-supermarket',
        navIndex: 5
      },
      {
        name: 'colleges',
        path: '/home/colleges',
        navIndex: 5
      },
      {
        name: 'storeList',
        path: '/home/storeList',
        navIndex: 5
      },
      {
        name: 'experts',
        path: '/home/experts',
        navIndex: 5
      },
      {
        name: 'OutsideStore',
        path: '/home/OutsideStore',
        navIndex: 5
      },
      {
        name: 'goodsList',
        path: '/home/goodsList',
        navIndex: 5
      },
      {
        name: 'good',
        path: 'good',
        navIndex: 5
      },
      {
        name: 'science-service',
        path: '/home/science-service',
        navIndex: 1
      },
      {
        name: 'serviceList',
        path: '/home/serviceList',
        navIndex: 1
      },
      {
        name: 'serviceOutStoreList',
        path: '/home/serviceOutStoreList',
        navIndex: 1
      },
      {

        name:'scienceOutStoreList',
        path: '/home/scienceOutStoreList',
        navIndex: 1
      },
      {
        name: 'serviceDetail',
        path: '/home/serviceDetail',
        navIndex: 1
      },
      {
        name: 'servicePay',
        path: '/home/servicePay',
        navIndex:1
      },
      {
        name: 'qixiao',
        path: '/home/qixiao',
        navIndex: 2
      },
      {
        name: 'patent-trust',
        path: '/home/patent-trust',
        navIndex: 3
      },
      {
        name: 'examination',
        path: '/home/examination',
        navIndex: 4
      },
      {
        name: 'policy',
        path: '/home/policy',
        navIndex: 6
      },
      {
        name: 'baike',
        path: '/home/baike',
        navIndex: 7
      },
      {
        name: 'media-report',
        path: '/home/media-report',
        navIndex: 8
      },
      {
        name: 'knowledgeDetail',
        path: '/home/knowledgeDetail',
        navIndex: 9
      },
      {
        name: 'mediaReportDetail',
        path: '/home/mediaReportDetail',
        navIndex: 10
      },
      {
        name: 'DemandHall',
        path: '/home/DemandHall',
        navIndex: 11
      },
      {
        name: 'demandDetail',
        path: '/home/demandDetail',
        navIndex: 12
      },
      {
        name: 'sciencePolicyDetail',
        path: '/home/sciencePolicyDetail',
        navIndex: 13
      },
      {
        name: 'ResourceRetrieval',
        path: '/home/ResourceRetrieval',
        navIndex: 16
      },
      {
        name: 'ResourceDetail',
        path: '/home/ResourceDetail',
        navIndex: 17
      },
      {
        name:'PatentSearch',
        path:'/home/PatentSearch',
        navIndex:18
      },
      {
        name:'PatentSearchDetail',
        path:'/home/PatentSearchDetail',
        navIndex:19
      },
      {
        name:'EnterpriseCertification',
        path:'/home/EnterpriseCertification',
        navIndex:20
      },
      {
        name: 'aboutus',
        path: '/home/aboutus',
        navIndex: 21
      },
    ];
}


export function getEnterpriseRouteConfig() {
  return [
    {
      name: 'enterprise-management-center',
      path: '/enterprise/enterprise-management-center',
      navIndex: 0
    },
    {
      name: 'enterprise-school-connection',
      path: '/enterprise/enterprise-school-connection',
      navIndex: 1
    },
    {
      name: 'patent-trusteeship',
      path: '/enterprise/patent-trusteeship',
      navIndex: 2
    },
    {
      name: 'enterprise-physical-examination',
      path: '/enterprise/enterprise-physical-examination',
      navIndex: 3
    },
    {
      name: 'science-policy',
      path: '/enterprise/science-policy',
      navIndex: 4
    },
    {
      name: 'collect-list',
      path: '/enterprise/collect-list',
      navIndex: 5
    },
    {
      name: 'history',
      path: '/enterprise/history',
      navIndex: 6
    },
    {
      name: 'enterprise-cultivate',
      path: '/enterprise/enterprise-cultivate',
      navIndex: 7
    },
    {
      name:'service-pay',
      path:'/enterprise/service-pay/:id',
      nwoIndex:5
    },
    {
      name:'annua-pay',
      path:'/enterprise/annua-pay',
      nwoIndex:6
    }
  ];
}

export function getNavConfig() {
    return [
      {name: '首页', index: 0, path:'/home/index'},
      {name: '科技服务', index: 1, path:'/home/science-service'},
      {name: '需求匹配', index: 2, path:'/home/qixiao'},
      {name: '专利托管', index: 3, path:'/home/patent-trust'},
      {name: '企业体检', index: 4, path:'/home/examination'},
      {name: '政策匹配', index: 6, path:'/home/policy'},
      {name: '关于我们', index: 21, path:'/home/aboutus'},
    ];

}


